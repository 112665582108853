<template>
  <div class="app-container">
    <div class="BulkCrmsCusAddressDetailLs">
      <el-row>
        <el-col :span="24">
          <el-form ref="addressDetailInfo" :model="addressDetailInfo" :rules="rules" label-width="80px"
                   class="demo-ruleForm">
            <el-form-item label="联系人" prop="contacts">
              <el-input v-model="addressDetailInfo.contacts"></el-input>
            </el-form-item>
            <el-form-item label="职务" prop="contactsPost">
              <el-input v-model="addressDetailInfo.contactsPost"></el-input>
            </el-form-item>
            <el-form-item label="手机" prop="contactsPhone">
              <el-input v-model="addressDetailInfo.contactsPhone"></el-input>
            </el-form-item>
            <el-form-item label="地址" prop="addressDetailed">
              <el-input placeholder="联系人地址" v-model="addressDetailInfo.addressDetailed" :disabled="true">
                <el-button slot="append" icon="el-icon-map" @click="showMap" type="primary">选择</el-button>
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <van-button type="primary" size="large" native-type="submit" @click="saveAddress('addressDetailInfo')">保存</van-button>
    </div>

    <!-- 百度地图位置选择 -->
    <BMapAddressSelect :addressDetailInfo="this.addressDetailInfo" ref="bmapAddressSelect"
                       @confirmMapAddress="confirmMapAddress"></BMapAddressSelect>

  </div>
</template>

<script>
import BMapAddressSelect from "@/components/BMapAddressSelect/index";

export default {
  name: "BulkCrmsCusAddressDetailEditLs",
  components: {BMapAddressSelect},
  data() {
    return {
      // 位置信息
      addressDetailInfo: {
        addressOtherid: '',
        addressId: '',
        contacts: '',
        contactsPost: '',
        contactsPhone: '',
        addressDetailed: '',
        addressLongitude: '',
        addressLatitude: '',
        addressProvince: '',
        addressCity: '',
        district: ''
      },
      rules: {
        contacts: [
          {required: true, message: '请输入联系人名称', trigger: 'blur'},
          {min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur'}
        ],
        contactsPost: [
          {required: true, message: '请输入联系人职务', trigger: 'blur'},
          {min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur'}
        ],
        contactsPhone: [
          {required: true, message: '请输入联系人手机 ', trigger: 'blur'},
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的电话",
            trigger: "blur",
          },
        ],
        addressDetailed: [
          {required: true, message: '请填写详细地址', trigger: 'blur'}
        ]
      },
      searchAddresKeywords: "",
      openMap: false,
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$ajax.post('/api/ajax/cusAddress/getBulkCrmsLsCusAddressList.json',
          {addressId: this.$route.params.name, addressOtherid: ""}).then(res => {
        if (res.status == 200) {
          this.addressDetailInfo.addressOtherid = res.data[0].addressOtherid
          this.addressDetailInfo.addressId = res.data[0].addressId
          this.addressDetailInfo.contacts = res.data[0].contacts
          this.addressDetailInfo.contactsPost = res.data[0].contactsPost
          this.addressDetailInfo.contactsPhone = res.data[0].contactsPhone
          this.addressDetailInfo.addressDetailed = res.data[0].addressDetailed
          this.addressDetailInfo.addressLongitude = res.data[0].addressLongitude
          this.addressDetailInfo.addressLatitude = res.data[0].addressLatitude
          this.addressDetailInfo.addressProvince = res.data[0].addressProvince
          this.addressDetailInfo.addressCity = res.data[0].addressCity
          console.log(this.addressDetailInfo)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })
    },
    saveAddress(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$ajax.post('/api/ajax/cusAddress/updateBulkCrmsLsCusAddress.json',
              {
                contacts: this.addressDetailInfo.contacts,
                contactsPhone: this.addressDetailInfo.contactsPhone,
                contactsPost: this.addressDetailInfo.contactsPost,
                addressDetailed: this.addressDetailInfo.addressDetailed,
                addressId: this.addressDetailInfo.addressId,
                addressLongitude: this.addressDetailInfo.addressLongitude,
                addressLatitude: this.addressDetailInfo.addressLatitude,
                addressOtherid: this.addressDetailInfo.addressOtherid,
                addressProvince: this.addressDetailInfo.addressProvince,
                addressCity: this.addressDetailInfo.addressCity,
                modifiedPeople: this.$store.getters.userId,
              }).then(res => {
            if (res.status == 200) {
              this.$message({
                showClose: true,
                message: '修改成功',
                type: 'success'
              });
              setTimeout(() => {
                this.$router.replace({path: '/BulkCrmsCusAddressDetailListLs/' + this.addressDetailInfo.addressOtherid})
              }, 200);
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('修改失败');
          })
        } else {
          console.log('未通过校验!!');
          return false;
        }
      });

    },
    /** 显示地图 */
    showMap() {
      this.$refs.bmapAddressSelect.show();
    },
    /** 确认地图地址 */
    confirmMapAddress(addressDetailInfo) {
      this.addressDetailInfo = addressDetailInfo;
    },
  },
}
</script>

<style scoped>
.app-container {
  background: white;
}

.BulkCrmsCusAddressDetailLs {
  margin: 15px 25px 15px 15px;
  background: white;
}

</style>
